@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Creepster&family=Oswald&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap');

body {
  margin: 0;
  p:0;
  font-family: Oswald, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace, Roboto;
}
html, body {
  margin: 0;
  padding: 0;
  width: 100&;
  height: 100%;
  box-sizing: border-box;
  font-family: sans-serif;
}

*, *::before, *::after {
  box-sizing: inherit;
}

a{
  text-decoration: none;
  color:black;
}

